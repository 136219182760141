import { t } from "./Helpers";


// TODO: ===============
// TODO: Fix bug where FocusManager jumps to previous focused (not new focused)
// TODO: CSS List styles
// TODO: Pre-load modal images on card hover
// TODO: ===============


export default class Sport {

	constructor () {
		window.modal.onBeforeOpen(this.onModalOpen);

		// Modal parts
		this.modalImage = document.getElementById("courseModalImage");
		this.modalName = document.getElementById("courseModalName");
		this.modalContent = document.getElementById("courseModalContent");
		this.modalTimes = document.getElementById("courseModalTimes");
	}

	onModalOpen = (modal, trigger) => {
		const courseId = trigger.dataset.course;
		const course = window.courses[courseId];

		this.modalImage.style.backgroundImage = `url(${course.img})`;
		this.modalName.textContent = course.title;
		this.modalContent.innerHTML = course.description;

		while (this.modalTimes.lastElementChild !== this.modalTimes.firstElementChild)
			this.modalTimes.removeChild(this.modalTimes.lastElementChild);

		course.dates.forEach((time, i) => {
			time = time.dates;

			this.modalTimes.appendChild(
				t("a", {
					href: course.enquire + "&date=" + i,
					click: this.onDateClick.bind(this, time),
				}, [
					t("span", {}, time),
					t("span", {"class":"button small"}, "Enquire")
				])
			);
		});
	};

	onDateClick = () => {
		//
	};

}
